// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "x_jQ d_jQ d_cv";
export var galleryMasonryImage = "x_jP d_jP d_w d_bR d_dB";
export var alignLeft = "x_qd d_fp d_bG d_dv";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignRight = "x_qf d_fr d_bH d_dx";
export var galleryContainer = "x_tq d_dW";
export var galleryContainerFull = "x_tr d_dT";
export var galleryRowWrapper = "x_ts d_cc";
export var galleryGuttersImage = "x_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "x_jR d_jR d_K d_cD";
export var galleryTextGutters = "x_jM d_jM d_cw";
export var galleryTextNoGutters = "x_jN d_jN d_cw";
export var galleryTextMasonry = "x_tt d_jM d_cw";
export var galleryImageWrapper = "x_tv d_fg d_Z";
export var descText = "x_tw d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "x_tx";