// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_vP d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_vQ d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_vR d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_vS d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_vT d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_vV d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_vW d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_vn y_vn";
export var heroExceptionNormal = "z_vp y_vp";
export var heroExceptionLarge = "z_vq y_vq";
export var Title1Small = "z_t3 y_t3 y_ty y_tz";
export var Title1Normal = "z_t4 y_t4 y_ty y_tB";
export var Title1Large = "z_t5 y_t5 y_ty y_tC";
export var BodySmall = "z_vh y_vh y_ty y_tS";
export var BodyNormal = "z_vj y_vj y_ty y_tT";
export var BodyLarge = "z_vk y_vk y_ty y_tV";