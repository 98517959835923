// extracted by mini-css-extract-plugin
export var alignDiscLeft = "w_sZ d_fp d_bG d_dv";
export var alignLeft = "w_qd d_fp d_bG d_dv";
export var alignDiscCenter = "w_s0 d_fq d_bD d_dw";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignDiscRight = "w_s1 d_fr d_bH d_dx";
export var alignRight = "w_qf d_fr d_bH d_dx";
export var footerContainer = "w_s2 d_dW d_bW";
export var footerContainerFull = "w_s3 d_dT d_bW";
export var footerHeader = "w_kf d_kf";
export var footerTextWrapper = "w_s4 d_w";
export var footerDisclaimerWrapper = "w_km d_km d_ck";
export var badgeWrapper = "w_s5 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "w_s6 d_bz d_bJ d_bN d_bL";
export var wide = "w_s7 d_cy";
export var right = "w_s8 d_bK";
export var line = "w_fk d_fl d_cv";
export var badgeDisclaimer = "w_s9 d_bC d_bP d_bJ";
export var badgeContainer = "w_tb d_bz d_bH d_bP";
export var badge = "w_tc";
export var padding = "w_td d_c7";
export var end = "w_tf d_bH";
export var linkWrapper = "w_tg d_dB";
export var link = "w_mC d_dB";
export var colWrapper = "w_th d_cx";
export var consent = "w_f d_f d_bC d_bP";
export var disclaimer = "w_tj d_bz d_bJ";
export var media = "w_tk d_bx d_dy";
export var itemRight = "w_tl";
export var itemLeft = "w_tm";
export var itemCenter = "w_tn";
export var exceptionWeight = "w_tp y_t0";