// extracted by mini-css-extract-plugin
export var alignLeft = "F_qd d_fp d_bG d_dv";
export var alignCenter = "F_bP d_fq d_bD d_dw";
export var alignRight = "F_qf d_fr d_bH d_dx";
export var menuContainer = "F_v2 d_dW";
export var menuContainerFull = "F_v3 d_dT";
export var menuMainHeader = "F_jC d_jC d_w d_c3";
export var menuComponentWrapper = "F_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "F_jH d_jH d_cy";
export var menuComponentText = "F_jD d_jD d_cn";
export var menuComponentTextDesign2 = "F_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "F_jJ d_jJ d_w d_Z";