// extracted by mini-css-extract-plugin
export var socialContainer = "G_v4";
export var instagramLink = "G_v5";
export var socialWrapperLeft = "G_qL d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "G_v6";
export var socialContentContainerFull = "G_v7";
export var instagramContainer = "G_v8";
export var twitterContainer = "G_v9";
export var facebookContainer = "G_wb";
export var socialErrorContainer = "G_wc";
export var facebookContainerWide = "G_wd";
export var twitterContainerWide = "G_wf";
export var socialParagraphCenter = "G_wg";
export var instaWrapper = "G_wh";
export var SubtitleSmall = "G_pd y_pd y_ty y_tL";
export var SubtitleNormal = "G_pf y_pf y_ty y_tM";
export var SubtitleLarge = "G_pg y_pg y_ty y_tN";
export var textLeft = "G_dv";
export var textCenter = "G_dw";
export var textRight = "G_dx";